











































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import FeatureImg from '@/components/dashboard/FeatureImg/index.vue'
import VisitVideo from '../../video.vue'
import { blobToJson } from '@/utils/helpers'

@Component({ components: { FeatureImg, VisitVideo } })
export default class PersonVisit extends Vue {
  @Ref() readonly videoNode: VisitVideo
  @Prop({ default: false }) showFlag: boolean
  @Prop({}) imageDetail: AnyObj

  private showImg = false
  private showDialog = false
  private bigPictureUrl = ''
  private videoLoading = false
  private showVideo = false

  mounted() {
    this.showDialog = this.showFlag
  }

  deactivated() {
    this.showImg = false
    this.showDialog = false
  }
  showBigPicture(url: string) {
    this.showImg = true
    this.bigPictureUrl = url
  }

  async lookVideo() {
    this.videoLoading = true
    const { areaId, captureTime, areaName } = this.imageDetail
    const { data } = await this.$api.base.getDevicesSearch({
      areaIds: [areaId],
      cascade: false
    })
    if (data.data && data.data.length > 0) {
      const { physicalId } = data.data[0]
      if (physicalId) {
        const resp = await this.$api.v2.getPlayBack(physicalId, {
          startTime: this.$moment(captureTime).subtract(15, 'seconds'),
          endTime: this.$moment(captureTime).add(15, 'seconds'),
          latest: true
        })
        if (resp.status === 200) {
          if (resp.data && resp.data.type === 'application/json') {
            const data: any = await blobToJson(resp.data)
            if (data.error) {
              let errorMessage = ''
              if (
                (data.error.description &&
                  data.error.description.indexOf('not configured with nvr') != -1) ||
                (data.error.message && data.error.message.indexOf('not configured with nvr') != -1)
              ) {
                errorMessage = 'Ipc设备未关联NVR和通道号信息'
              } else if (
                (data.error.description &&
                  data.error.description.indexOf('device not online') != -1) ||
                (data.error.message && data.error.message.indexOf('device not online') != -1)
              ) {
                errorMessage = '视频设备不在线'
              }
              this.$message({
                message: errorMessage.length > 0 ? errorMessage : data.error.message,
                type: 'error'
              })
            }
          } else {
            this.showVideo = true
            const obj = {
              area: { areaPath: areaName },
              src: URL.createObjectURL(resp.data),
              startTime: captureTime
            }
            this.$nextTick(() => {
              if (this.videoNode) this.videoNode.open([obj])
            })
          }
        } else if (resp.data.error) {
          let errorTip = ''
          if (
            resp.data.error.description &&
            resp.data.error.description.indexOf('pull failed') != -1
          ) {
            errorTip = '未找到对应时间的视频记录'
          }

          if (errorTip != '') {
            localStorage.setItem('isShowMessage', 'false')
            this.$message({
              message: errorTip,
              type: 'error'
            })
          }
        }
      }
    } else if (data.data && data.data.length === 0) {
      this.$message({
        message: '目标区域下未绑定设备，无法获取视频',
        type: 'error'
      })
    }
    this.videoLoading = false
  }
}
