















































































































































































import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import validator from '@/components/common/validator'
import { getAllTags } from '@/utils/getTags'
import { compressImg, imageRotate } from '@/components/common/compressImg'
import { ElForm } from 'element-ui/types/form'
import _ from 'lodash'
import { is } from '@/utils/helpers'

@Component({
  name: 'addPersonal'
})
export default class AddPersonal extends Vue {
  @Ref() readonly formNode: ElForm
  @Ref() readonly hiddenInput: HTMLDivElement

  private alreadyPersonObj: any = null
  private alreadyExistTips = false
  private alreadyPersonName: any = null
  private showFlag = false
  private loading = false
  private genderOptions = [
    {
      value: 'male',
      label: '男'
    },
    {
      value: 'female',
      label: '女'
    }
  ]
  private tagTypeOptions = [
    { value: 'vip', label: 'VIP', type: 'vip', disabled: true },
    { value: 'white', label: '白名单', type: 'white', disabled: true },
    { value: 'black', label: '黑名单', type: 'black', disabled: true }
  ]
  private ruleForm: any = { tagType: '', tagIds: [] }
  private getAllTags: any[] = []
  private rules: any = {
    tagType: [{ required: true, message: '请选择标签类型', trigger: 'change' }],
    tagIds: [{ required: true, message: '请至少选择一种标签', trigger: 'change' }],
    name: [
      {
        required: true,
        validator: validator.checkName({
          num: 256,
          error1: '请输入姓名',
          error2: '姓名应在256字符内，请重新输入'
        }),
        trigger: 'blur'
      }
    ],
    email: [{ validator: validator.Email({}), trigger: 'blur' }]
  }
  private imageData: string | null = null
  private imageUrl: string | null = null
  private tagTips = false
  private cascadeOptions: AnyObj = {}
  private tagIdsOptions: any[] = []
  private tipShow = false
  private tipText = ''
  private type = 'add'

  @Watch('imageData')
  watchImage(val: string) {
    if (val) this.personSearch(val)
  }

  @Watch('showFlag')
  watchFlag() {
    if (!this.showFlag) this.formNode.resetFields()
  }

  get isAddMode() {
    return this.type === 'add' || this.type === 'detailAdd'
  }

  get isEditMode() {
    return this.type === 'edit'
  }

  get title() {
    return this.isEditMode ? '编辑人员信息' : '注册'
  }

  get isUploadImg() {
    return this.type === 'add'
  }

  deactivated() {
    this.showFlag = false
  }

  private async getTagTree() {
    const data: AnyObj = {}
    this.tagTypeOptions.forEach(item => {
      const child = this.getAllTags.filter(i => i.type === item.type)
      if (child.length > 0) data[item.type] = child
    })
    return data
  }
  private TypeChange(e: string) {
    this.ruleForm.tagIds = []
    this.tagIdsOptions = this.cascadeOptions[e]
  }
  private tagChange(e: any) {
    this.tagTips = e.length >= 2
  }
  private async personSearch(val: string | null) {
    if (this.isAddMode && val) {
      const isBase64 = val.indexOf('base64,') > -1
      const params = {
        [isBase64 ? 'imageData' : 'imageUrl']: isBase64 ? val.split(',')[1] : val,
        register: true,
        level: 'high'
      }
      this.alreadyExistTips = false
      const { data } = await this.$api.base.getPersonsSimilar(params)
      if (data.data && data.code === 0 && data.data.length > 0) {
        const { tags, name } = data.data[0]
        if (tags.length > 0) {
          this.alreadyExistTips = true
          this.alreadyPersonObj = data.data[0]
          this.alreadyPersonName = name
        } else {
          this.alreadyPersonObj = ''
        }
      }
    }
  }

  private focusInput() {
    this.tipShow = false
    this.tipText = ''
  }
  private checkUrl(url: string | null) {
    const regUrl = /^(ht|f)tps?:\/\/.*/

    if (!url) {
      this.tipShow = true
      this.tipText = '网址为空，请重新输入'
      return false
    } else if (!regUrl.test(url)) {
      this.tipShow = true
      this.tipText = '网址无效，请重新输入'
      return false
    }
    return true
  }
  private loadPic() {
    const flag = this.checkUrl(this.imageUrl)
    if (flag) {
      this.personSearch(this.imageUrl)
      this.imageData = this.imageUrl
    }
  }

  private intoDetail() {
    this.$emit('alreadyPerson', this.alreadyPersonObj)
  }
  private handleFile(e: any) {
    this.compress(e.target.files ? e.target.files[0] : null)
    this.imageUrl = null
  }
  private getImage(data: string) {
    this.imageData = data
  }
  private rotateImage(file: File) {
    imageRotate(file, this.getImage)
  }
  private async compress(file: File) {
    if (!file) {
      return
    }
    const result = await compressImg(file)
    result.onload = (e: any) => {
      this.rotateImage(e.target.result)
    }
    if (!this.imageData && !result.onload) {
      this.imageData = result.result as string
    }
    const input: any = this.hiddenInput
    input.value = ''
    return
  }
  private dragover(e: any) {
    e.preventDefault()
  }

  private drop(e: any) {
    e.preventDefault()
    this.compress(e.dataTransfer.files ? e.dataTransfer.files[0] : null)
  }
  private cancelDialog() {
    this.showFlag = !this.showFlag
  }
  private message(msg: string, type: MessageType) {
    this.$message({ message: msg, type: type })
  }

  private uploadHeadImg() {
    this.hiddenInput.click()
  }

  async open(type: string, data: any = {}) {
    this.type = type
    this.showFlag = true
    this.tagTips = false
    this.tipShow = false
    this.alreadyExistTips = false
    this.imageData = null
    this.imageUrl = null
    this.tagIdsOptions = []
    this.$nextTick(async () => {
      this.ruleForm = { ...data, tagType: '', tagIds: [] }
      if (type === 'detailAdd') {
        this.ruleForm.imageUrl = data.tripBestImageUrl || data.imageUrl
        this.ruleForm.personId = data.personId || data.id
      }
    })
    this.getAllTags = await getAllTags()
    this.cascadeOptions = await this.getTagTree()
  }
  get fields() {
    return [
      'name',
      'age',
      'gender',
      'tel',
      'email',
      'birthday',
      'position',
      'externalId',
      'description',
      'tagIds'
    ]
  }
  get params() {
    const { age } = this.ruleForm
    const params: AnyObj = { ...this.ruleForm }
    if (age) {
      params.age = parseInt(age)
    }

    const res = _.pick(params, this.fields)
    if (this.isAddMode) {
      if (this.alreadyExistTips) {
        res.personId = ':New'
      } else if (this.ruleForm.personId) {
        res.personId = this.ruleForm.personId
      }
      const { imageUrl } = this.ruleForm
      const isBase64 = this.imageData && this.imageData.indexOf('base64,') > -1
      const image = {
        [isBase64 ? 'imageData' : 'imageUrl']: this.imageData
          ? isBase64
            ? this.imageData.split(',')[1]
            : this.imageData
          : imageUrl
          ? imageUrl
          : null
      }
      Object.assign(res, image)
    }
    return res
  }
  async addPersonnel() {
    const { data } = await this.$api.base.getPersonsCreate(
      _.mapValues(this.params, v => (is.ava(v) ? v : null))
    )
    if (data && data.code === 0) {
      this.message('注册成功', 'success')
      this.$emit('refresh', 'add')
      this.showFlag = false
    }
  }
  async editPersonnel() {
    const params = _.omit(this.params, ['imageUrl', 'imageData'])
    const { data } = await this.$api.base.getPersonsEdit(
      this.ruleForm.id,
      _.mapValues(params, v => (is.ava(v) ? v : null))
    )
    if (data && data.code === 0) {
      this.message('编辑成功', 'success')
      this.$emit('refresh', 'name')
      this.showFlag = false
    }
  }
  submit() {
    if (this.isAddMode && !this.ruleForm.imageUrl && !this.imageData) {
      this.message('人员照片不能为空', 'info')
      return false
    }
    this.formNode.validate(async valid => {
      if (valid) {
        this.loading = true
        if (this.isAddMode) {
          await this.addPersonnel()
        } else {
          await this.editPersonnel()
        }
        this.loading = false
      }
    })
  }
}
