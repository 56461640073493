









































































import CopyText from '@/components/common/CopyText.vue'
import { getAllTags } from '@/utils/getTags'
import SecurityText from '@/components/common/SecurityText/index.vue'
export default {
  // directives 为自定义函数
  directives: {
    cancelClose: {
      // 首次打开渲染
      inserted: (el: any, binding: any) => {
        const data = binding.value.option
        setTimeout(() => {
          const tags: any = document.querySelectorAll('.el-tag--info')
          const tagNames = []
          for (const ele of data) {
            if (ele.disabled) {
              tagNames.push(ele.name)
            }
          }
          if (tags && tags.length > 0) {
            for (const ele of tags) {
              if (tagNames.includes(ele.innerText) && ele.children[1]) {
                ele.children[1].style.display = 'none'
              }
            }
          }
        })
      },
      // 多次打开渲染
      componentUpdated: (el: any, binding: any) => {
        const data = binding.value.option
        setTimeout(() => {
          const tags: any = document.querySelectorAll('.el-tag--info')
          const tagNames = []
          for (const ele of data) {
            if (ele.disabled) {
              tagNames.push(ele.name)
            }
          }
          if (tags && tags.length > 0) {
            for (const ele of tags) {
              if (tagNames.includes(ele.innerText) && ele.children[1]) {
                ele.children[1].style.display = 'none'
              }
            }
          }
        })
      }
    }
  },
  props: {
    parentCascadeOptions: Array
  },
  data() {
    return {
      props: {
        multiple: true
      },
      typeLabel: {
        tagType: ''
      },
      nameLabel: {
        tagListValue: []
      },
      rules: {
        tagType: [{ required: true, message: '请选择标签类型', trigger: 'change' }],
        tagListValue: [{ required: true, message: '请至少选择一种标签', trigger: 'change' }]
      },
      name: '',
      id: '',
      showFlag: false,
      loading: false,
      oldTagsId: [],
      tagTips: false,
      cascadeOptions: [],
      tagTypeOptions: [
        { value: 'vip', label: 'VIP', type: 'vip', disabled: true },
        { value: 'white', label: '白名单', type: 'white', disabled: true },
        { value: 'black', label: '黑名单', type: 'black', disabled: true }
      ],
      tagSelectOptions: [],
      tagList: [],
      disabledSelected: false, // 若有一个标签名称不可删除，则标签类型就不能选择
      nameVisible: false
    }
  },
  components: {
    CopyText,
    SecurityText
  },
  deactivated() {
    this.showFlag = false
  },
  methods: {
    cancelDialog() {
      this.showFlag = false
    },
    async TypeChange(e: string) {
      this.nameLabel.tagListValue = []
      const cachedOptions = this.cascadeOptions
      cachedOptions.map((item: AnyObj) => {
        if (e === item.value) {
          this.tagSelectOptions = item.children
        }
      })
    },
    async tagChange(eve: any) {
      if (eve.length >= 2) {
        this.tagTips = true
      } else {
        this.tagTips = false
      }
    },
    async getTaglistTree() {
      const data: any[] = []
      this.tagTypeOptions.forEach(async (item: AnyObj) => {
        const obj: AnyObj = {}
        obj.value = item.value
        obj.label = item.label
        obj.disabled = item.disabled
        obj.type = item.type
        const childList: any[] = []
        this.tagList.forEach((item2: AnyObj) => {
          item2.label = item2.name
          item2.value = item2.name

          if (item2.type === item.type) {
            childList.push(item2)
          }
        })
        if (childList.length > 0) {
          obj.children = childList
        }
        data.push(obj)
      })
      return data
    },
    async open(type: string, data: AnyObj = {}) {
      this.nameVisible = false
      this.tagList = await getAllTags()
      this.cascadeOptions = await this.getTaglistTree()
      if (type === 'editTags') {
        this.typeLabel.tagType = ''
        this.nameLabel.tagListValue = []
        this.tagTips = false
        this.showFlag = true
        this.name = data.name
        this.id = data.id

        if (data.tags) {
          this.oldTagsId = data.tags.map((r: AnyObj) => r.id)

          data.tags.map((item: AnyObj) => {
            item.label = item.name
            item.value = item.name
            this.typeLabel.tagType = item.type
            this.nameLabel.tagListValue.push(item.name)

            this.cascadeOptions.map((list: AnyObj) => {
              if (item.type === list.type) {
                this.tagSelectOptions = list.children
              }
            })
          })
        }
      }
    },
    async submit() {
      this.$refs.formNode.validate(async (valid: boolean) => {
        if (valid) {
          try {
            this.loading = true
            const tagId: any[] = []
            this.nameLabel.tagListValue.forEach(async (tagValue: string) => {
              this.tagSelectOptions.map((tagList: AnyObj) => {
                if (tagValue === tagList.name) {
                  tagId.push(tagList.id)
                }
              })
            })
            const resultTag = await this.$api.base.getUpdateTag(this.id, { tagIds: tagId })
            if (resultTag.data.code === 0) {
              this.$message({ message: '编辑成功', type: 'success' })
              this.$emit('refresh', 'name')
              this.showFlag = false
            }
            this.loading = false
          } catch (error) {
            this.loading = false
            this.showFlag = true
          }
        }
      })
    }
  }
}
